import { $count, $isarray, $isuuid, $json, $ok, $tounsigned } from "foundation-ts/commons";
import { $dateorder } from "foundation-ts/compare";
import { TSDate } from "foundation-ts/tsdate";
import { TSError } from "foundation-ts/tserrors";
import { Verb } from "foundation-ts/tsrequest";
import { Comparison, Nullable, UUID } from "foundation-ts/types";
import { $inspect } from "foundation-ts/utils";
import {  SessionStatus, SignatureStatus } from "g1-commons/lib/doxecureModelTypes";
import { DocumentRepresentation, SectionCategory, SessionActorType, SignatureMode } from "g1-commons/lib/doxecureTypes";
import { CreateDocumentBody, CreateSessionBody, DocumentDto, SectionDto, SessionDto, SessionsQueryRestrictions, SignatureDto, UpdateDocumentInfos, UpdateSessionBody, UpdateSignatureBody } from "g1-commons/lib/doxecureClientTypes";

import { fileNameString, optlog } from "../utils/functions";

import apiClient, { assertResponse } from "./apiClient";

export const createSessionForUser = async (session: SessionDto, userID:Nullable<UUID>): Promise<SessionDto> => {
    if ($ok(session.apid)) { throw "createSessionForUser(): session.apid should NOT BE SET" ; }
    if (!$ok(userID)) { throw `createSessionForUser() cannot have a valid user's profileID` ; }

    const body: CreateSessionBody = {
        creator: userID!,
        start: session.validity.start,
        end: session.validity.end,
        title: session.title,
    } ;
    if ($ok(session.description) && (session.description!.length > 0)) { body.description = session.description ; }
    if ($ok(session.expeditor) && (session.expeditor.apid !== userID!)) { body.expeditor = session.expeditor.apid ; }
    if ($isuuid(session.templateId)) { body.templateId = session.templateId ; }
    _addSignatureToBody(body, session.signatures, true) ;
    _addDocumentsToBody(body, session.documents, true) ;
    optlog(`Added ${$count(body.signatures)} signatures to future new session`) ;
    optlog(`Added ${$count(body.documents)} documents to future new session`) ;
    return apiClient.createSession(body)
                    .then(([createdSession, status]) => assertResponse(createdSession, status)) ;
};

export const patchSessionForUser = async (session: SessionDto): Promise<SessionDto> => {
    if (!$isuuid(session.apid)) { throw "patchSessionForUser(): session.apid should be a valid UUID" ; }
    // QUESTION: Doit-on tout passer dans le body ?
    const body: UpdateSessionBody = {
        creator: session.creator,
        start: session.validity.start,
        end: session.validity.end,
        expeditor: session.expeditor.apid,
        title: session.title,
    } ;
    if ($ok(session.description) && (session.description!.length > 0)) { body.description = session.description ; }
    _addSignatureToBody(body, session.signatures, false) ;
    _addDocumentsToBody(body, session.documents, false) ;
    optlog(`Added ${$count(body.signatures)} signatures for session ${session.apid} update`) ;
    optlog(`Added ${$count(body.documents)} documents for session ${session.apid} update`) ;
    optlog($inspect(body)) ;
    return apiClient.updateSession(session.apid!, body)
                    .then(([updatedSession, status]) => assertResponse(updatedSession, status)) ;
};

export const getSectionsForUser = async (
    userID:UUID,
    role: SessionActorType|SessionActorType[],
    restrictions: SessionsQueryRestrictions = SessionsQueryRestrictions.None,
    category?: SectionCategory,
    fullTextSearch?: string,
    nocookie?: boolean): Promise<SectionDto[]> => {
    
    optlog(`getSectionsForUser(${userID}, ${role}, ${restrictions}, ${category}, ${fullTextSearch})`) ;
    
    if ($isarray(role) && (role as SessionActorType[]).length === 1) {
        role = (role as SessionActorType[])[0] ;
    }

    if ($isarray(role)) {
        const knownSessions = new Set<string>() ;
        const base = new Map<SectionCategory, SessionDto[]>() ;
        for (let r of role as SessionActorType[]) {
            const [resp, status] = await apiClient.getSections({
                role:r,
                user: userID,
                status: category,
                restrictions: restrictions,
                textSearch: fullTextSearch
            }, nocookie) ;
            
            if (!$ok(resp)) { throw new TSError(`getSectionsForUser() for role ${r} did return status ${status}`) ; }

            for (let section of resp!) {
                let list = base.get(section.category) ;
                let addlist = false ;
                if (!$ok(list)) { list = [] ; addlist = true ; }

                for (let session of section.sessions) {
                    if ($ok(session.apid) && !knownSessions.has(session.apid!)) {
                        knownSessions.add(session.apid!) ;
                        list!.push(session) ;
                    }
                }
                if (addlist && list?.length) { base.set(section.category, list) ; } // add only if we have sessions in our list
            }
        }

        // here, since sessions can come from different roles, we need to sort them
        const ret:SectionDto[] = [] ;
        base.forEach((sessions, category) => {
            if ((category === 'signed')) {
                ret.push({category:category, sessions:sessions.sort(_compareSignedSessionDto)}) ;
            } else if (category === 'refused') {
                ret.push({category:category, sessions:sessions.sort(_compareRefusedSessionDto)}) ;
            } else if (category === 'canceled') {
                ret.push({category:category, sessions:sessions.sort(_compareCanceledSessionDto)})
            } else if ((category === 'archived') || (category === 'draft')) {
                ret.push({category:category, sessions:sessions.sort(_compareDescendingSessionDto)}) ;
            } else if (category === 'expired') {
                ret.push({category:category, sessions:sessions.sort(_compareExpiredSessionDto)}) ;
            } else {
                ret.push({category:category, sessions:sessions.sort(_compareSessionDto)}) ;
            }
        }) ;
        return ret ;
    }

    // here each section's session is sorted in the backend. No need to sort it again
    // except if you want to change the way they are sorted
    return apiClient.getSections({
        role: role as SessionActorType,
        user: userID,
        status: category,
        restrictions: restrictions,
        textSearch: fullTextSearch
    }, nocookie).then(([sections, status]) => assertResponse(sections, status)) ;
};

export const createTemplateSessionForUser = async (session: SessionDto, userId: Nullable<UUID>): Promise<SessionDto> => {
    if ($ok(session.apid)) { throw "createSessionForUser(): session.apid should NOT BE SET" ; }
    if (!$ok(userId)) { throw `createSessionForUser() cannot have a valid user's profileID` ; }

    const body: CreateSessionBody = {
        creator: userId!,
        start: session.validity.start,
        end: session.validity.end,
        title: session.title,
        expeditor: session.expeditor.apid!
    } ;
    if ($ok(session.description) && (session.description!.length > 0)) { body.description = session.description ; }
    _addSignatureToBody(body, session.signatures, true) ;
    optlog(`Added ${$count(body.signatures)} signatures to template session`) ;
    return apiClient.createTemplateSession(body)
                    .then(([createdSession, status]) => assertResponse(createdSession, status)) ;
} ;

export const patchTemplateSessionForUser = async (session: SessionDto): Promise<SessionDto> => {
    if (!$isuuid(session.apid)) { throw "patchTemplateSessionForUser(): session.apid should be a valid UUID" ; }

    const body: UpdateSessionBody = {
        expeditor: session.expeditor.apid,
        start: session.validity.start,
        end: session.validity.end,
        title: session.title,
    } ;
    if ($ok(session.description) && (session.description!.length > 0)) { body.description = session.description ; }
    _addSignatureToBody(body, session.signatures, false) ;
    return apiClient.patchTemplateSession(session.apid!, body).then(([ret, status]) => assertResponse(ret, status) ) ;
}

export const getTemplateSessions = async (): Promise<SessionDto[]> => {
    return await apiClient.listTemplateSessions().then(([sessions, status]) => assertResponse(sessions, status)) ;
}

export const deleteTemplateSession = async (sessionId: UUID): Promise<boolean> => {
    return await apiClient.deleteTemplateSession(sessionId) ;
}

export const getTokenSessionStruct = async (token: string) => {
    return await apiClient.getTokenSessionStruct(token) ;
}

export const getOneSessionForUser = async (sessionId: Nullable<UUID>, token?: Nullable<string>): Promise<SessionDto> => {
    if (!$isuuid(sessionId)) { throw "getOneSessionForUser(): sessionId should be a valid UUID"; }
    return apiClient.getSession(sessionId!, token).then(([session, status]) => assertResponse(session, status)) ;
};

export const deleteOneSessionForUser = async (sessionId: UUID | undefined | null): Promise<UUID> => {
    if (!$isuuid(sessionId)) { throw "deleteOneSessionForUser(): sessionId should be a valid UUID"; }
    return apiClient.deleteSession(sessionId!).then(([deletedSessionId, status]) => assertResponse(deletedSessionId, status)) ;
};

export const publishSession = async (sessionId: UUID | undefined | null): Promise<boolean> => {
    if (!$isuuid(sessionId)) { throw "publishSession(): sessionId should be a valid UUID"; }
    return apiClient.publishSession(sessionId!).then(([flag, status]) => assertResponse(flag, status)) ;
}

export const abortSession = async (sessionId: UUID | undefined | null): Promise<boolean> => {
    if (!$isuuid(sessionId)) { throw "abortSession(): sessionId should be a valid UUID"; }
    return apiClient.abortSession(sessionId!).then(([flag, status]) => assertResponse(flag, status)) ;
}

export const transferSessionForUser = async (sessionId: UUID | undefined | null): Promise<SessionDto> => {
    if (!$isuuid(sessionId)) { throw "transferSessionForUser(): sessionId should be a valid UUID"; }
    return apiClient.request(`/sessions/${sessionId!}/transfer`, Verb.Patch).then(([ret, _status]) => ret as SessionDto) ;
};

export const downloadAllDocuments = async (sessionId: UUID | undefined | null): Promise<any> => {
    if (!$isuuid(sessionId)) { throw "downloadAllDocuments(): sessionId should be a valid UUID"; }
    return apiClient.downloadSignedDocuments(sessionId!).then(([ret, _status]) => ret) ;
};

export const downloadDocument = async (sessionId: UUID | undefined | null, documentId: UUID | undefined | null): Promise<any> => {
    if (!$isuuid(sessionId)) { throw "downloadDocument(): sessionId should be a valid UUID"; }
    if (!$isuuid(documentId)) { throw "downloadDocument(): documentId should be a valid UUID"; }
    return apiClient.downloadDocument(sessionId!, documentId!).then(([ret, _status]) => ret) ;
};

export const getDocumentRepresentation = async (sessionId: Nullable<UUID>, documentId:Nullable<UUID>, token?: Nullable<string>): Promise<DocumentRepresentation> => {
    if (!$isuuid(sessionId)) { throw "getDocumentRepresentation(): sessionId should be a valid UUID"; }
    if (!$isuuid(documentId)) { throw "getDocumentRepresentation(): documentId should be a valid UUID"; }
    return apiClient.documentRepresentation(sessionId!, documentId!, token).then(([representation, status]) => assertResponse(representation, status)) ;
};

// simpler to make body as any here... but that's not good practice
function _addSignatureToBody(body:any, signatures:SignatureDto[]|undefined|null, creation:boolean) {
    if ((creation && $count(signatures) > 0) || (!creation && $ok(signatures))) {
        body.signatures = signatures!.map(s => {
            const apid = s.signer.apid ;
            const sb:UpdateSignatureBody = {
                signer: $isuuid(apid) ? apid! : { firstName:s.signer.firstName, lastName:s.signer.lastName, email:s.signer.email },
                rank:s.rank,
                type:s.signatureType,
                phase:s.phase,
                mode:$ok(s.signatureMode) ? s.signatureMode! : SignatureMode.Visible
            } ;
            if (!creation && $ok(s.apid)) { sb.apid = s.apid ; } // for update only
            return sb ;
        }) ;
    }
}

function _addDocumentsToBody(body:any, documents:DocumentDto[]|undefined|null, creation:boolean) {
    if ((creation && $count(documents) > 0) || (!creation && $ok(documents))) {
        body.documents = documents!.map(d => {
            const apid = d.apid ;
            if (apid && !creation) { 
                const ret:UpdateDocumentInfos = { 
                    apid:apid,
                    lastPageSignRows:$tounsigned(d.lastPageSignRows)
                } 
                optlog('Will update document infos',ret.apid, ret.lastPageSignRows) ;
                return ret ;
            }
            else if (!apid && $ok(d.localSource)) {
                const index = d.localSource!.indexOf(',') ;
                const ret:CreateDocumentBody = {
                    fileName:fileNameString(d.fileName),
                    fileType:d.fileType,
                    mimeType:d.mimeType,
                    size: d.size,
                    pagesCount: d.pagesCount,
                    localSource:index === -1 ? d.localSource! : d.localSource!.slice(index+1),
                    lastPageSignRows:$tounsigned(d.lastPageSignRows)
                } ;
                optlog('Will add document \n'+$json(ret.fileName)) ;
                return ret ;
            }
            return undefined ;
        }) ;
    }
}

function _compareSessionDto(A: SessionDto, B: SessionDto): NonNullable<Comparison> 
{ 
    return $dateorder(A.validity.end, B.validity.end) ;
}

function _compareDescendingSessionDto(A: SessionDto, B: SessionDto): NonNullable<Comparison> {
    return $dateorder(B.validity.end, A.validity.end) ;
}

function _compareSignedSessionDto(A: SessionDto, B: SessionDto): NonNullable<Comparison> {
    return $dateorder(B.signatures?.last()?.signatureDate, A.signatures?.last()?.signatureDate) ;
}

function _compareRefusedSessionDto(A: SessionDto, B: SessionDto): NonNullable<Comparison> {
    let dateA: Nullable<TSDate> = A.validity.end ;
    let dateB: Nullable<TSDate> = B.validity.end ;
    let refusedSignature = A.signatures?.find(signature => signature.signatureStatus === SignatureStatus.Refused) ;
    if ($ok(refusedSignature)) { dateA = $ok(refusedSignature!.refusalDate) ? TSDate.fromIsoString(refusedSignature!.refusalDate) : refusedSignature!.signatureDate! ; }

    refusedSignature = B.signatures?.find(signature => signature.signatureStatus === SignatureStatus.Refused) ;
    if ($ok(refusedSignature)) { dateB = $ok(refusedSignature!.refusalDate) ? TSDate.fromIsoString(refusedSignature!.refusalDate) : refusedSignature!.signatureDate! ; }
    return $dateorder(dateB, dateA) ;
}

function _compareCanceledSessionDto(A: SessionDto, B: SessionDto): NonNullable<Comparison> {
    let dateA: Nullable<TSDate> = A.validity.end ;
    let dateB: Nullable<TSDate> = B.validity.end ;
    let canceledSignature = A.signatures?.find(signature => signature.signatureStatus === SignatureStatus.Canceled) ;
    if ($ok(canceledSignature)) { dateA = canceledSignature?.signatureDate ; }
    
    canceledSignature = B.signatures?.find(signature => signature.signatureStatus === SignatureStatus.Canceled) ;
    if ($ok(canceledSignature)) { dateB = canceledSignature?.signatureDate ; }
    return $dateorder(dateB, dateA) ;
}

function _compareExpiredSessionDto(A: SessionDto, B: SessionDto): NonNullable<Comparison> {
    let dateA: Nullable<TSDate> = A.validity.end ;
    let dateB: Nullable<TSDate> = B.validity.end ;
    if (A.status === SessionStatus.Refused) {
        let refusedSignature = A.signatures?.find(signature => signature.signatureStatus === SignatureStatus.Refused) ;
        if ($ok(refusedSignature)) { dateA = $ok(refusedSignature!.refusalDate) ? TSDate.fromIsoString(refusedSignature!.refusalDate) : refusedSignature!.signatureDate! ; }
    }
    if (B.status === SessionStatus.Refused) {
        let refusedSignature = B.signatures?.find(signature => signature.signatureStatus === SignatureStatus.Refused) ;
        if ($ok(refusedSignature)) { dateB = $ok(refusedSignature!.refusalDate) ? TSDate.fromIsoString(refusedSignature!.refusalDate) : refusedSignature!.signatureDate! ; }
    }
    if (A.status === SessionStatus.Canceled) {
        let canceledSignature = A.signatures?.find(signature => signature.signatureStatus === SignatureStatus.Canceled) ;
        if ($ok(canceledSignature)) { dateA = canceledSignature?.signatureDate ; }
    }
    if (B.status === SessionStatus.Canceled) {
        let canceledSignature = B.signatures?.find(signature => signature.signatureStatus === SignatureStatus.Canceled) ;
        if ($ok(canceledSignature)) { dateB = canceledSignature?.signatureDate ; }
    }

    return $dateorder(dateB, dateA) ;
}
