import { Text } from "@chakra-ui/react";
import React, { useEffect } from "react";

import { $ok } from "foundation-ts/commons";

import { TemporaryChildProps } from "../../components/layouts/TemporaryAuthenticatedLayout";

export const DownloadSignedDocumentsView = ( { sharedData }: TemporaryChildProps ) => {
    useEffect(() => {
        if ($ok(sharedData)) { 
            const link = document.createElement('a') ;
            link.href = sharedData! as string;
            link.download = "session_files.zip" ;
            link.click();        
        }
    }, [sharedData]) ;

    return <Text>Récupération des documents de la session</Text>
};
