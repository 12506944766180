import React from "react";

import { $length, $ok } from "foundation-ts/commons";
import { Nullable } from "foundation-ts/types";

import { TemporaryAuthenticatedLayout } from "../components/layouts/TemporaryAuthenticatedLayout";
import { DownloadSignedDocumentsView } from "../containers/signedDocuments/DownloadSignedDocumentsView";

import { g1call } from "../services/apiClient";
import { getTokenSessionStruct } from "../services/session.service";
import { bytesToDownloadURL } from "../utils/functions";

const DownloadSignedDocumentsPage = () => {
    return (
        <TemporaryAuthenticatedLayout getData={async (token: string) => {
            const tokenSessionStruct = await getTokenSessionStruct(token) ;
            if (!$ok(tokenSessionStruct)) { return null ; }

            let url:Nullable<string> = undefined ;
            const ret = await g1call('download zip of signed documents', c => c.downloadSignedDocuments(tokenSessionStruct!.sessionId, token)) ;
            if ($ok(ret)) { const [zip,] = ret! ; url = bytesToDownloadURL(zip) ; }
            return $length(url) === 0 ? null : url ;
        }}>
            <DownloadSignedDocumentsView />
        </TemporaryAuthenticatedLayout>
    ) ;
}

export default DownloadSignedDocumentsPage ;